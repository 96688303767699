<template>
  <div class="modal-custom">
    <div class="modal-custom-content" style="width: fit-content">
      <div class="modal-custom-header">
        <div class="d-flex justify-content-start align-items-center">
          <div>
            <h1 class="d-flex justify-content-start align-items-center">
              Select date
            </h1>
          </div>
        </div>
        <button
          class="btn border shadow-sm btn-close"
          @click="closePreview('close')"
        >
          &times;
        </button>
      </div>
      <div class="modal-custom-body">
        <v-date-picker
          class="inline-calendar"
          v-model="dateFilter"
          :value="value"
          :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD',
          }"
          :attributes="[
            {
              dot: 'red',
              dates: [new Date()],
            },
          ]"
          mode="date"
          trim-weeks
          is-dark
        />
      </div>
      <div class="modal-custom-footer">
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-sm btn-light" @click="dateFilter = null">
            Clear
          </button>
          <button
            class="btn btn-sm btn-primary"
            @click="closePreview(dateFilter)"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      dateFilter: null,
    };
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.dateFilter = this.value;
        }
      },
      immediate: true,
    },
  },
  methods: {
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
  },
  beforeDestroy() {
    this.closePreview("close");
  },
};
</script>