var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-custom"},[_c('div',{staticClass:"modal-custom-content",staticStyle:{"width":"fit-content"}},[_c('div',{staticClass:"modal-custom-header"},[_vm._m(0),_c('button',{staticClass:"btn border shadow-sm btn-close",on:{"click":function($event){return _vm.closePreview('close')}}},[_vm._v(" × ")])]),_c('div',{staticClass:"modal-custom-body"},[_c('v-date-picker',{staticClass:"inline-calendar",attrs:{"value":_vm.value,"model-config":{
          type: 'string',
          mask: 'YYYY-MM-DD',
        },"attributes":[
          {
            dot: 'red',
            dates: [new Date()],
          },
        ],"mode":"date","trim-weeks":"","is-dark":""},model:{value:(_vm.dateFilter),callback:function ($$v) {_vm.dateFilter=$$v},expression:"dateFilter"}})],1),_c('div',{staticClass:"modal-custom-footer"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('button',{staticClass:"btn btn-sm btn-light",on:{"click":function($event){_vm.dateFilter = null}}},[_vm._v(" Clear ")]),_c('button',{staticClass:"btn btn-sm btn-primary",on:{"click":function($event){return _vm.closePreview(_vm.dateFilter)}}},[_vm._v(" Apply ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('div',[_c('h1',{staticClass:"d-flex justify-content-start align-items-center"},[_vm._v(" Select date ")])])])
}]

export { render, staticRenderFns }